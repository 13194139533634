import { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from "@material-ui/styles";
import PopUpModal from '../PopUpModal/PopUpModal';
import { Grid, Box, Typography, Divider } from '@material-ui/core';
import FindSpecialistForm from '../FindSpecialistForm/FindSpecialistForm';
import CheckCircleOutlineIcon from "../../img/checkCircleOutline.svg";
import ErrorIcon from "../../img/error-fill.svg";
import './popUpFindSpecialist.css'


const PopUpFindSpecialist = (props) => {
    const theme = useTheme();
    const innerContainerRef = useRef(null);
    const [innerContainerHeight, setInnerContainerHeight] = useState("550px")
    const [isPatient, setIsPatient] = props.patientHandlers
    const [formOutcome, setFormOutcome] = useState({});
    const maxTimeToShowFormOutcome = 3000;
    const formOutcomeTxt = {
        succeeded:{
            message:'estamos en cambio.\nMuchas gracias'
        },
        error:{
            ups: 'Ups',
            message: 'inténtalo más tarde'
        }
    }

    const onClosePopUp = useCallback(() => {
        setIsPatient(false);
        setFormOutcome({});
        setInnerContainerHeight("550px")
    },[setIsPatient, setFormOutcome]);

    useEffect(()=>{
        if(formOutcome.state){
            setInnerContainerHeight(innerContainerRef.current.offsetHeight);
            setTimeout( onClosePopUp, maxTimeToShowFormOutcome)
        }
    },[formOutcome, onClosePopUp])
    return (
        <PopUpModal showModal={isPatient} onClickClose={onClosePopUp} width="400px" height={innerContainerHeight}>
            <Box ref={innerContainerRef} style={{padding:"40px 30px 30px 30px"}}>
                <Grid container direction='column' justifyContent='center'>
                    <Grid item>
                        <Typography variant='h5'>
                            Ponte en buenas manos
                        </Typography>
                    </Grid>
                    <Grid item style={{paddingTop:"5px"}}>
                        <Typography variant='body1' align="justify" >
                            Te ponemos en contacto con el Especialista Quantum acreditado más adecuado.
                        </Typography>
                    </Grid>
                    <Divider  style={{marginTop:'10px'}}/>
                    <Grid item>
                        {formOutcome.state ?
                            < Grid container direction="column" alignItems='center' style={{width:"100%"}}>
                                <Grid item className='pop-up-find-specialist-outcome-icon'>
                                        {formOutcome.state !== 'error' ?
                                            <CheckCircleOutlineIcon fill={theme.palette.success.main} height={60} alt="circleButton" />
                                        :   
                                            <ErrorIcon height={60} alt="errorIcon" />
                                        }
                                </Grid>
                                <Grid item align='center'>
                                    <Typography variant="body1" style={{whiteSpace:"pre-line"}} >
                                        {`${formOutcomeTxt[formOutcome.state].ups ? formOutcomeTxt[formOutcome.state].ups+', ' : ''}${formOutcome.values.firstName} ${formOutcomeTxt[formOutcome.state].message}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        :
                            <FindSpecialistForm setFormOutcome={setFormOutcome} />
                        }
                    </Grid>
                </Grid>
            </Box>
        </PopUpModal>
    )
}

export default PopUpFindSpecialist