import { forwardRef } from 'react';
import { Box, Paper } from '@material-ui/core'

// Img and graphics
import CloseIcon from '../../img/close-fill.svg';

// Style
import './popUpModal.css'

const PopUpModal = ({showModal, onClickClose, height, width, ...props}, ref) => {
    return (
        <Box className={`pop-up-main ${showModal ? 'pop-up-main-visible' : 'pop-up-main-hidden'}`}>
            <Paper ref={ref} className='pop-up-modal' style={{height:height,  width:width}}>
            <CloseIcon className='pop-up-modal-close-icon' onClick={onClickClose} height={25}/>
                {props.children}
            </Paper>
        </Box>
    )
}

export default forwardRef(PopUpModal)