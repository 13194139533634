import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import { Button, Typography, Box } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { Link as UiLink} from "@material-ui/core";
import { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";

import "./HeaderSimple.css";

import Logo from "../../img/logo-alpha.svg";
import LogoNeg from "../../img/logo-alpha-neg.svg";

const HeaderSimple = ({ setIsPatient }) => {

    const classes = {
        root: {
            flexGrow: 1,
        },
    };
    const linkNameList = ['Encuentra Especialista'];
    const onClickLinkList = [()=>{setIsPatient(true)}]
    const [hover, setHover ] = useState(false)
    const onMouseEnter = (e) => {
        e.target.style.textDecoration= "underline";
    }
    const onMouseLeave = (e) => {
        e.target.style.textDecoration= "none";
    }
    const onMouseL = () => {
        setHover(false);
    }
    const onMouseE = () => {
        setHover(true);
    }

    const onClick = (e) => {
        setIsPatient(true);
    }
    return (
            <AppBar color="transparent" elevation={0}>
                <Toolbar>
                    <Box 
                        display="flex" 
                        alignItems="center"
                        style={{flexGrow:1}}
                    >
                        <Link
                        to="/"
                        aria-label="Home"
                        onMouseEnter= {onMouseE}
                        onMouseLeave= {onMouseL}
                        >
                            {hover ? <LogoNeg alt="logo" height={30} /> : <Logo alt="logo" height={30} />}       
                        </Link>
                    </Box>
                    {linkNameList.map((linkName,i) => {
                        return(<Typography 
                        variant= "body2"
                        className="text-link text-clickable bar-nav-elem"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onClick={onClickLinkList[i]}
                        > 
                            {linkName}   
                        </Typography>)
                    })}
                </Toolbar>
            </AppBar>
    )
}

export default HeaderSimple