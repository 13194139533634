import { Grid, Typography, Box } from '@material-ui/core'
import { useState, useEffect } from 'react';

import LogoEmpty from "../../img/checkBox.svg"; //"../../img/logo-x-empty.svg";
import LogoFilled from "../../img/checkedBox.svg"; //"../../img/logo-x-fill.svg";
import Arrow from "../../img/arrow.svg";
import text from "../../txt/text.json";
import "./WishesList.css";


const WishesList = () => {
    const initTitle = text['wishes-list']['init-title']; 
    const finalTitle = text['wishes-list']['final-title']; 
    const wishes = text['wishes-list']['wishes'];
    const finalMsg = text['wishes-list']['final-msg'];
    const maxClicks = 3;
    const [clickCounter, setClickCounter] = useState(0);
    const [clicked, setClicked] = useState(false);
    const [wish, setWish] = useState(Math.floor(Math.random()*100) % wishes.length);
    const [opacityWishes, setOpacityWishes]= useState(1);
    const [opacityWishesArrow, setOpacityWishesArrow]= useState(0);
    var seenWishes = [wish];

    function getWishId(){
        let  nextId = Math.floor(Math.random()*100) % wishes.length;
        while(seenWishes.includes(nextId)){
            nextId = Math.floor(Math.random()*100) % wishes.length;
        }
        seenWishes.push(nextId);
        console.log(nextId)
        return nextId;
    }

    const onClick = () => {
        setClicked(true);
        setOpacityWishes(0)
        if (clickCounter !== maxClicks ){
            // update Clicks, checkbox State and element to click
            setTimeout(()=>{
                setClicked(false);
                setWish(getWishId());
                setOpacityWishes(1);
            }, 500);
            setClickCounter(clickCounter + 1);
        } 
    }

    useEffect(() => {
        if (clickCounter ===  maxClicks){
            setTimeout(()=>{
                setOpacityWishesArrow(1);
            }, 500);
        }
    }, [clickCounter])

    return (
        <Grid container direction="column" spacing={1} style={{height:"100%", paddingLeft:50, paddingTop:150}}>
            <Grid item align="left">
                { clickCounter !== maxClicks ?
                    <Typography className="text-animated"  variant="h3" style={{ textDecoration:"underline", fontWeight:"bold", fontFamily: "Playfair Display"}}>
                        {initTitle}
                    </Typography> : 
                    <Typography className="fade-in"  variant="h3" style={{ fontWeight:"bold", fontFamily:"Playfair Display"}}>
                        {finalTitle}
                    </Typography>
                }
            </Grid>
            <Grid item> 
                <Grid container  direction="row" alignItems="center" style={{height:"100%"}}>
                    <Grid item  className="text-animated" style={{opacity:opacityWishes, paddingLeft:15}}>
                        { clickCounter !== maxClicks ? 
                            clicked ?  <LogoFilled alt="checked" height={26} style={{cursor:"pointer"}} /> : <LogoEmpty alt="unchecked" height={26} onClick={onClick} style={{cursor:"pointer"}}/>
                            : null
                        }
                    </Grid>
                    <Grid item align="left" >
                        {clickCounter === maxClicks ? 
                            <Box className="fade-in">
                                <Typography  variant="h6" style={{whiteSpace:"pre-line"}}>
                                    {finalMsg}
                                </Typography>
                                <Arrow  className="text-animated" height={100} style={{opacity:opacityWishesArrow, marginTop: 10, marginLeft: -50, fill:"white"}}/>
                            </Box>
                            : 
                            <Typography  className="text-animated" variant="h6" style={{paddingLeft:20, opacity:opacityWishes}}>
                                {wishes[wish]}
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default WishesList
