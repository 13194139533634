import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Box from '@material-ui/core/Box';

import{ useEffect, useState } from 'react';

import Footer from '../Footer/Footer';
import CircularButton from '../CircularButton/CircularButton';
import WelcomeBox from '../WelcomeBox/WelcomeBox';
// import Carousel from '../Carousel/Carousel';
import WishesList from '../WishesList/WishesList';
import { amber, green, blue, pink  } from '@mui/material/colors';

import "./Landing.css";


/** 
 * Declarating css styles in JSX
const styles = {
    'landing-root': {
        backgroundColor:"#F8F2F2",
        height:"100vh",
    }
}
*/ 

const Landing = (props) => {

    const azul ={'200': '#00ADC7' , '50': '#52ADC7'};
    const rosa ={'200': '#EE509C' , '50': '#EE719C'};
    const verde ={'200': '#00A551' , '50': '#52A551'};
    const amarillo ={'200': '#FAA919' , '50': '#FAA966'};
    const [hideFooter, setHideFooter] = props.footerHandlers;
    const footerHeight = [40, 70] // [desktop, mobile] They represent pixels
    const screenSize = props.screenSize;
    const widgetHandler = false;
    const colorsTest = [amber, green, blue, pink];
    const colorsOurs = [amarillo, verde, azul, rosa];
    const colors = colorsOurs;
    const cLight = 200;
    const cLow = 50;
    const colorIntensity = false; // Change banground color animation
    const [colorLight, setColorLight] = useState(cLight);
    const [colorLow, setColorLow] = useState(cLow);
    const [countEven, setCountEven] = useState(0);
    const [countOdds, setCountOdds] = useState(0);
    const [countOddsNext, setCountOddsNext] = useState(1);
    const [countEvenNext, setCountEvenNext] = useState(1);
    const [gradientDegree, setGradientDegree] = useState(45);
    const [changeBackground, setChangeBackground]= useState(false);

    const styles = {
        'gradient-animation-even':{
            backgroundImage: "linear-gradient(" + gradientDegree + "deg,"+ colors[countEven][colorIntensity?colorLow : colorLight] +" 35%, "+ colors[countEvenNext][colorLight] +" 70%)",
            //transition: "background-image 2s ease",
            animation: "Gradient 15s ease infinite",
            backgroundSize: "800% 800%",
            transition: "opacity 1s ease-in-out",
            opacity: changeBackground ? 0 : 1,
        },

        'gradient-animation-odds':{
            backgroundImage: "linear-gradient("+ gradientDegree + "deg,"+ colors[countOdds][colorIntensity?colorLow : colorLight] +" 35%, "+ colors[countOddsNext][colorLight] +" 70%)",
            //transition: "background-image 2s ease",
            animation: "Gradient 15s ease infinite",
            backgroundSize: "800% 800%",
        }

    }

    const getRandomAngle = () =>{
        // It is not used
        return (Math.random() * 1000 % 360 + 1)

    }

    const bgSameColorChange = () =>{
        //set Colors to a different intensity
        if (colorLow == colorLight) {setColorLow(cLow)}
        if (changeBackground){
            let newEven = (countEven + 2) % colors.length
            setCountEven(newEven);
            setCountEvenNext(newEven)
        } else {
            let newOdd = (countOddsNext + 2) % colors.length
            setCountOdds(newOdd);
            setCountOddsNext(newOdd);
        };
        setChangeBackground(!changeBackground);
        //setGradientDegree(getRandomAngle())
    }

    const bgDifferentColorChange = () =>{
        //set Colors to High
        if (colorLow !== colorLight) {setColorLow(colorLight)}
        if (changeBackground){
            setCountEvenNext((countOdds + 1) % colors.length);
            setCountEven((countOdds) % colors.length);
        } else {
            setCountOddsNext((countEvenNext) % colors.length);
            setCountOdds((countEvenNext + 1) % colors.length);
        };
        setChangeBackground(!changeBackground);
        //setGradientDegree(getRandomAngle())
    }

    // useEffect(() => {
    //     let vh = window.innerHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    //  }, []);

    return (
        <div>
            <Box className="landing-wrapping" bgcolor={colors[countOdds][colorLow]} style={styles['gradient-animation-odds']}>
            </Box>
            <Box className="landing-wrapping" bgcolor={colors[countEven][colorLow]} style={styles['gradient-animation-even']}>
            </Box>
            <Box className="landing-root" onClick={colorIntensity? bgSameColorChange : bgDifferentColorChange}> 
            {/* <img class="demo-bg" src={bgImg}/>  */}
                <Grid container direction="column" justifyContent="center" align='center' 
                    style={{height:"100%", marginBottom:screenSize.isMobile ? -footerHeight[1]+"px": -footerHeight[0]+"px"}}>
                    <Grid item>
                        <Grid container direction="row" style={{height:"100%"}}>
                            <Grid item xs={12} lg={4} style={{
                                paddingLeft:screenSize.isMobile ? null : 100,  height:"100%"}}>
                                <WelcomeBox setSubscribed={props.setSubscribed} screenSize={screenSize}/>
                            </Grid> 
                            {screenSize.isDesktop &&
                            <Grid item lg={8} style={{ paddingRight:50, height:'100%'}}>
                                {/* <Carousel /> */}
                                <WishesList />
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                    {widgetHandler && 
                        <Grid item>
                        <Grid container direction="row" justifyContent='center' alignItems='center' style={{minHeight:34}}>
                            <Grid item >
                                    <ButtonGroup>
                                        <CircularButton value={1} />
                                        <CircularButton value={2}/>
                                        <CircularButton value={3}/>
                                    </ButtonGroup>
                            </Grid>
                        </Grid> 
                    </Grid>}
                </Grid>
                {hideFooter ? null : <Footer screenSize={screenSize} heightRelative={footerHeight} />}
            </Box>
        </div>
    )
}

export default Landing
