import axios from 'axios';
import {
    isTokenActive, getUserIdFromAccessToken,
    refreshAndSetAuthTokens,
    revokeAuthTokens
} from './authHelpers';
import { sessionVariables } from './sessionHelpers';

const BASE_URL = `${process.env.REACT_APP_API_BASEPATH}:${process.env.REACT_APP_API_PORT}/${process.env.REACT_APP_API_SUBDOMAIN}/`; 

/**
 * Creates a blank axios instance
 */
const publicThirdPartyRequest = axios.create();

/**
 * Creates an axios instance pointing out our API
 */
const publicRequest = axios.create({
    baseURL : BASE_URL,
});

let logOutUserOnClientFn,
    history,
    addMessages;
const setHistoryForInterceptors = (historyFromHook) => {history = historyFromHook};
const setUpCallbackForInterceptors = (callback) => { logOutUserOnClientFn = callback};
const setClientNotificiationsForInterceptors = (fn) => {addMessages = fn};
/**
 * Creares an axios instance pointing out our API with an user configuration to refresh her access
 */
function createAuthRefreshRequest(){
    const userRefreshRequest = axios.create({
        baseURL: BASE_URL,
        headers: {
            refresh_authorization : `Bearer_Refresh ${sessionVariables.get('refreshToken')}`
        }
    });
    userRefreshRequest.interceptors.request.use(
        async (config) => {
            const accessToken = config.headers.refresh_authorization;
            if(!isTokenActive(accessToken)){
                await revokeAuthTokens();
                logOutUserOnClientFn();
                return Promise.reject('Invalid Auth')
            }
            return config
        }, (error) => {
            console.log('refresh no auth error')
            return Promise.reject(error);
        }
    )

    userRefreshRequest.interceptors.response.use(
        (response) => { 
            return response
        }, (error) => {
            if(error.response.data?.cause === 'sr_500'){
                console.debug("Refresh Tokens revoked")
                sessionVariables.remove('accessToken');
                sessionVariables.remove('refreshToken');
                logOutUserOnClientFn();
                return Promise.reject('Invalid Auth')
            }else{
                return Promise.reject(error);
            }
        }
    )
    return userRefreshRequest;
}

/**
 * Creates an axios instance with an user configuration
 */
function createAuthRequest(){
    const userRequest = axios.create({
        baseURL: BASE_URL,
        headers: {
            authorization : `Bearer ${sessionVariables.get('accessToken')}`,
            refresh_authorization : `Bearer_Refresh ${sessionVariables.get('refreshToken')}`
        }
    });
    userRequest.interceptors.request.use(
        async (config) => {
            const accessToken = config.headers.authorization;
            if(!isTokenActive(accessToken)){
                const userId = getUserIdFromAccessToken(accessToken)
                await refreshAndSetAuthTokens(userId)
                config.headers.authorization = `Bearer ${sessionVariables.get('accessToken')}`; 
                config.headers.refresh_authorization = `Bearer_Refresh ${sessionVariables.get('refreshToken')}`;
            }
            return config
        }, (error) => {
            return Promise.reject(error)
        }
    )
    return userRequest;
}


/**
 * Perform a request and stop the thread at least a minimum set
 */
const minimumLoadingTime = 2000; //ms
async function requestMinimumTimeoutWrapper( request, requestParameters={}, minimumTimeout = minimumLoadingTime){
    try{
        const date = new Date()
        const startLoadingTime = date.getUTCMilliseconds()
        const res = await request(requestParameters);
        const pendingTime = minimumTimeout - (date.getUTCMilliseconds() - startLoadingTime)
        if(pendingTime > 0 ) await new Promise((resolve) => { setTimeout(resolve, pendingTime)});
        return res;
    }catch(error){
        throw error
    }
}

export { publicThirdPartyRequest, publicRequest, createAuthRequest, createAuthRefreshRequest,
     requestMinimumTimeoutWrapper, setHistoryForInterceptors, setUpCallbackForInterceptors,
     setClientNotificiationsForInterceptors }