
import Box  from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";


//import YouTube  from "@material-ui/icons/YouTube";
//import Email  from "@material-ui/icons/Email";
//import FacebookIcon from '@material-ui/icons/Facebook';
import FacebookIcon from '../../img/logo-facebook.svg';
import Youtube from '../../img/logo-youtube.svg';
import Email from "../../img/logo-email.svg"

import "./Footer.css"
import { useTheme } from "@material-ui/styles";

const Footer = ({screenSize, heightRelative}) => {
    const facebookIconColor = "white" //"#4267B2";
    const youtubeIconColor = "white"  //'#FF3333';
    const emailIconColor= "white";
    const defaultIconColor = "black"; //"grey";

    const onClick = (e, type) => {
        if(type=="text"){
            e.target.style.textDecoration="none";
        } else {
                e.currentTarget.setAttribute("fill",defaultIconColor)
        }
    }

    const onMouseEnter = (e, type) => {
        if(type=="text"){
            e.target.style.textDecoration= "underline";
        } else {
            if(type=="facebook"){
                e.currentTarget.setAttribute("fill",facebookIconColor)
            } else if (type=="youtube"){
                e.currentTarget.setAttribute("fill",youtubeIconColor)
            } else if (type=="email"){
                e.currentTarget.setAttribute("fill",emailIconColor )
            }
        }
    }

    const onMouseLeave = (e, type) => {
        if(type=="text"){
            e.target.style.textDecoration="none";
        } else {
                e.currentTarget.setAttribute("fill",defaultIconColor)
        }
    }


    const theme = useTheme();
    return (
        <Box position="relative" bottom="0px" width="100%" height={screenSize.isMobile ? heightRelative[1]:heightRelative[0]}>

            <Grid container direction="row" padding="10px" justifyContent={screenSize.isMobile ? "center" : "space-between"}
                alignItems="center" style={{height:"100%",paddingLeft:"30px", paddingRight:"30px"}}>
                <Grid item align={screenSize.isMobile ? "center" : "left"} xs={12} sm={6} style={{order:screenSize.isMobile ? "2" : "1"}}> 
                    <Typography 
                        variant= "body2"
                        style={{color:defaultIconColor}}>
                        &copy; 2022 Quamtum Xrroid Institute
                    </Typography>
                </Grid>
                <Grid item style={{order:screenSize.isMobile ? "1" : "2", }}> 
                    <Grid container direction="row" alignItems="center" spacing={1} style={{height:"100%"}}>
                        {/* <Grid item>
                            <Typography 
                                href="mailto:info@qxinsitute.com"
                                target="_newtab"
                                component="a"
                                variant= "body2"
                                className="text-footer text-link"
                                onMouseEnter={(e)=> onMouseEnter(e,"text")}
                                onMouseLeave={(e)=> onMouseLeave(e, "text")}
                                >
                                Contacto
                            </Typography>
                        </Grid> */}
                        <Grid item>
                                <IconButton size="small" aria-label="email"
                                component="a" onClick= {(e)=>{onClick(e, "email")}}
                                href="mailto:info@qxinsitute.com"  target="_newtab">
                                    <Email fill={defaultIconColor} 
                                    onMouseEnter= {(e)=>{onMouseEnter(e, "email")}}
                                    onMouseLeave= {(e)=>{onMouseLeave(e, "email")}}
                                    height={25}/>
                                </IconButton>
                        </Grid>
                        <Grid item>
                                <IconButton size="small"  aria-label="youtube channel" 
                                    component="a" onClick= {(e)=>{onClick(e, "youtube")}}
                                    href="https://www.youtube.com/channel/UCepXRajPReVhU_ZsEevlw1A"
                                    >
                                    <Youtube fill={defaultIconColor}
                                    onMouseEnter= {(e)=>onMouseEnter(e, "youtube")}
                                    onMouseLeave= {(e)=>onMouseLeave(e, "youtube")}  height={25}
                                    /> 
                                    {/*<YouTube color="primary"/> */}
                                </IconButton>
                        </Grid>
                        <Grid item>
                                <IconButton size="small" aria-label="facebook" 
                                    component="a" onClick= {(e)=>{onClick(e, "facebook")}}
                                    href="https://www.facebook.com/qxinstitute"
                                    >
                                    <FacebookIcon fill={defaultIconColor}
                                    onMouseEnter= {(e)=>onMouseEnter(e, "facebook")}
                                    onMouseLeave= {(e)=>onMouseLeave(e, "facebook")}
                                    height={25}/>
                                    {/* <FacebookIcon color="primary"/> */}
                                </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Footer
