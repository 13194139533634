/**
 * This module contains useful helpers to deal with session variables on the browser
 */
 import axios from 'axios';
import { publicRequest } from './requestMethods';

let storage = localStorage;
const ipAndBrowserVariablesStorage = sessionStorage;

function setStorage(type){
    switch(type){
        case('session'):
            storage = sessionStorage;
            break;
        default:
            storage = localStorage;
    }
};

const variableNames = {
    paymentIntentId: 'paymentIntentId',
    purchaseOption: 'purchaseOption',
    userPurchaseSession: 'userPurchaseSession',
    accessToken: 'token',
    refreshToken: 'refreshToken',
    userLocaleId: 'userLocaleId',
    userLocaleCode: 'userLocaleCode'
};

const ipVariableNames = {
    currencyId: 'currencyId',
    localeCode: 'localeCode',
    localeId: 'localeId',
    countryCode: 'countryCode',
    inEU: 'inEU',
    countryLanguage: 'countryLanguage',
};

const browserVariableNames = {
    browserLocale: 'browserLocale'
};

const ipAndBrowserVariableNames = {
    ... ipVariableNames,
    ... browserVariableNames
};

function get(name){
    return storage.getItem(variableNames[name]);
};

function set(name, value){
    if(name in variableNames){
        storage.setItem(variableNames[name], value);
    }
};

function remove(name){
    if(name in variableNames){
        storage.removeItem(variableNames[name]);
    }
};

function hasOwnProperty(name){
    if(name in variableNames){
        return storage.hasOwnProperty(variableNames[name]);
    }else{
        return false;   
    }
};

const sessionVariables = {
    get,
    set,
    remove,
    hasOwnProperty
};

// storage event callback
const onSessionUpdate = (e) => {
    const {sessionVariable, value} = e;
    // Reload client to keep state consistency between tabs
    window.location.reload();
};

const getSessionGeoInfo = async () => {
    try{
        const sessionGeoInfo = {}
        const res = await axios.get("https://ipapi.co/json/");
        const data = res.data
        if(data){
            const geoInfoVariables = {
                countryCode: 'country_code',
                inEU: 'in_eu',
                countryLanguage: 'languages',
            };
            Object.entries(geoInfoVariables).forEach( ([key, resKey], ) => {
                let value 
                if(resKey === 'languages'){
                    value = data[resKey].split(',')[0]
                }else{
                    value = data[resKey];
                }
                sessionGeoInfo[key] = value 
            })
        }
        return sessionGeoInfo;
    }catch(error){
        console.log(error)
        throw error
    }
};

const getBrowserLocales = (options = {}) => {
    let locales = undefined;
    const defaultOptions = {
      languageCodeOnly: false,
    };
    const opt = {
      ...defaultOptions,
      ...options,
    };
    const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
    if (browserLocales) {
        locales = browserLocales.map(locale => {
            const trimmedLocale = locale.trim();
            return opt.languageCodeOnly
                ? trimmedLocale.split(/-|_/)[0]
                : trimmedLocale;
        });
    }
    return locales
};

function getReliableLocale(){
    let localeId, localeCode;
    if(storage.hasOwnProperty(variableNames.userLocaleId) && storage.hasOwnProperty(variableNames.userLocaleCode)){
    // User defined
        localeId = storage.getItem(variableNames.userLocaleId);
        localeCode = storage.getItem(variableNames.userLocaleCode);
    }else{
        localeId = ipAndBrowserVariablesStorage.getItem(ipAndBrowserVariableNames.localeId);
        localeCode = ipAndBrowserVariablesStorage.getItem(ipAndBrowserVariableNames.localeCode);
    }
    return {localeId, localeCode};
};

const getIPAndBrowserVariable = (name) => {
    return ipAndBrowserVariablesStorage.getItem(ipAndBrowserVariableNames[name]);
};

const setIPAndBrowserVariable = (name, value) => {
    if( name in ipAndBrowserVariableNames){
        ipAndBrowserVariablesStorage.setItem(ipAndBrowserVariableNames[name], value);
    }
};

const getAPIAvailLocale = async (localeCandidatesList) => {
    const getLocaleCode = (localeEntry) => {
        let localeCode =''
        if(localeEntry){
        localeCode = localeEntry.countryId ? `${localeEntry.languageId}-${localeEntry.countryId}` : localeEntry.languageId;
        }
        return localeCode
    }
    let apiAvailLocale;
    for(let localeIndex = 0; localeIndex < localeCandidatesList.length; localeIndex++){
        try{
            const [ language, country]= localeCandidatesList[localeIndex].split('-');
            const params = {
                language
            }
            const res = await publicRequest.get('/visitors/get-locales', { params });
            const availLocale = res.data.localeList;
            if(availLocale.length > 0){
                let defaultLocaleForLanguage;
                for(let i = 0; i < availLocale.length; i++){
                    let localeEntry = availLocale[i];
                    if(localeEntry.countryId && country){
                        if(localeEntry.countryId.toLowerCase() === country.toLowerCase()){
                            apiAvailLocale = {
                                localeId : localeEntry.id,
                                localeCode : getLocaleCode(localeEntry)
                            };
                            break;
                        }
                    }else if(localeEntry.isDefault){
                        defaultLocaleForLanguage = localeEntry;
                    }
                }
                if(apiAvailLocale){
                    break;
                }else if(defaultLocaleForLanguage){
                    apiAvailLocale = {
                        localeId : defaultLocaleForLanguage.id,
                        localeCode : getLocaleCode(defaultLocaleForLanguage)
                    };
                }
            }
        }catch(error){
            // Continue the loop
            console.debug(error)
        }
    }
    if(!apiAvailLocale){
        const res = await publicRequest.get('/visitors/get-default-locale');
        const defaultLocale = res.data.locale;
        apiAvailLocale = {
            localeId : defaultLocale.id,
            localeCode: getLocaleCode(defaultLocale)
        }
    }
    return apiAvailLocale;
}

const getAPIAvailCurrency = async (country, inEU) => {
    const params = {
        country,
        inEU
    }
    const res = await publicRequest.get('/visitors/get-avail-currency-for-country', { params });
    return res.data;
} 

const setVisitorLocaleAndCurrency = async () => {
    try{
        const sessionGeoInfo = await getSessionGeoInfo();
        const browserLocales = getBrowserLocales();
        browserLocales.push(sessionGeoInfo.countryLanguage)
        const locale = await getAPIAvailLocale(browserLocales);
        const currency = await getAPIAvailCurrency(sessionGeoInfo.countryCode, sessionGeoInfo.inEU);
        if(locale && currency){
            setIPAndBrowserVariable('currencyId', currency.currency);
            setIPAndBrowserVariable('localeId', locale.localeId);
            setIPAndBrowserVariable('localeCode', locale.localeCode);
            Object.entries(sessionGeoInfo).forEach( ([key, value], ) => {
                setIPAndBrowserVariable(key, value);
            });
        }else{
            // TODO: Custum Error
            throw new Error
        }
    }catch(error){
        // TODO: implement error handling
        console.debug(error)
    }

}

const clearSessionVariables = () => {
    storage.clear();
}

export {
    setStorage,
    sessionVariables,
    onSessionUpdate,
    setVisitorLocaleAndCurrency,
    getIPAndBrowserVariable,
    setIPAndBrowserVariable,
    getReliableLocale,
    clearSessionVariables
};