import { useState, useEffect } from "react";
import { createTheme } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
// Aux components
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
// Pages
import Home from "./pages/home/Home";
import SubscriptionSuccess from "./pages/subscriptionSuccess/SubscriptionSuccess";
// Components
import HeaderSimple from "./components/HeaderSimple/HeaderSimple";
import PopUpFindSpecialist from './components/PopUpFindSpecialist/PopUpFindSpecialist';
// Styles
import "./App.css";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Roboto',
        'raleway',
        'Nunito',
        'Noto Sans',
        'Helvetica Neue',
        'Arial',
        'sans-serif'
      ].join(','),
      h5:{
        fontFamily: 'Noto Sans ,Monstserrat, sans-serif',
        fontWeight: "bold",
        color:"black",
      },
      subtitle2:{
        color:"grey",
      }
    }
  })

  const [hideHeader, setHideHeader] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [isPatient, setIsPatient] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const isMobile =  useMediaQuery(theme.breakpoints.down('xs')) // Smaller than 600px
  const isTablet =  useMediaQuery(theme.breakpoints.between('sm','md')) // Between 600 and 1279px 
  const isDesktop =  useMediaQuery(theme.breakpoints.up('lg')) // Larger than 1279,... 
  const screenSize = { isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop } 

  useEffect(() => {
    if (isSubscribed){
      setTimeout(()=> {setIsSubscribed(false)}, 2000);
    }
  }, [isSubscribed])


  // const subscribed = () => {
  //   // This is fired if the user has just been subscribed 
  // }


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop /> 
            <div className="App">
              <HeaderSimple setIsPatient={setIsPatient}/>
              <PopUpFindSpecialist  patientHandlers={[isPatient, setIsPatient]}/>
              <Route path='/' exact render={(props) => (
                <Home subscribed={isSubscribed} setSubscribed={setIsSubscribed} screenSize={screenSize} 
                  headerHandlers={[hideHeader, setHideHeader]} footerHandlers= {[hideFooter, setHideFooter]}/>
              )}/>
              <Route path='/subscription/success' >
                <SubscriptionSuccess screenSize={screenSize} />
              </Route>
            </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
