import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// Style
import './buttonLoading.css';


const ButtonLoading = ({isLoading, label, ...props}) => {
    const theme = useTheme();
    const styles = {
        circularProgress:{
            color: props.hasOwnProperty("color") ? theme.palette[props.color].main: theme.palette.primary.main,
        }
    };
    return (
        <Button disabled={isLoading ? true : false} {...props}>
            {label}

            <div className={`button-loading-progress-container-hidden ${isLoading && 'button-loading-progress-container-active'}`}>
                <CircularProgress size="2em" style={styles.circularProgress}  />
            </div>
        </Button>
    )
}

export default ButtonLoading
