
import Landing from "../../components/Landing/Landing";

const Home = (props) => {
    return (
        <>
            <Landing subscribed={props.isSubscribed} setSubscribed={props.setSubscribed} screenSize={props.screenSize} 
              headerHandlers={props.headerHandlers} footerHandlers= {props.footerHandlers} />
        </>
    )
}

export default Home