import { useState, useEffect } from "react"
import { Grid, Button, TextField, Typography } from "@material-ui/core"
//Requests
import { requestMinimumTimeoutWrapper } from "../../aux/requestMethods";
import { subscribeToEmailListFn } from "../../aux/emailListQueries";
import { useTheme } from "@material-ui/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from "../../img/checkCircleOutline.svg";
import ErrorIcon from "../../img/error-fill.svg";




const Subscribtion = ({setSubscribed, screenSize}) => {
    const theme = useTheme();
    // TODO: It is a temporary solution to avoid CORS policies 
    // Deprecated due to heroku free plans removal 
    //'https://stormy-cliffs-96348.herokuapp.com/'
    const proxyCORS =  'https://cors-anywhere-6bcm.onrender.com/' 
    const subscritionAPI = 'https://scripts.dreamhost.com/add_list.cgi';
    const url = new URL(proxyCORS + subscritionAPI);
    const paramsAPI = {
        list: 'news',
        domain: 'qxinstitute.com',
    };
    const statesManagement = ['initial', 'loading', 'success', 'error'];

    const [emailState, setEmailState] = useState('');
    const [nameState, setNameState] = useState('');
    const [submitState, setSubmitState] = useState(statesManagement[0]); 

    const [subscriptionState, setSubscriptionState] =useState({
        email: '',
        name: '',
        requestError:false,
        alreadySubscribed:false,
    });


    useEffect(
        ()=> {
            if (submitState === statesManagement[2] || submitState === statesManagement[3]){
                setTimeout(() => {setSubmitState(statesManagement[0])}, 3000);
            }else if(submitState === statesManagement[0]){
                resetForm();
            }
        }, 
        [submitState],
        );

    const resetForm = () => {
        setNameState('');
        setEmailState('');
    };

    const request = async (url) => {
        setSubmitState(statesManagement[1]);
        const requestStatus = {
            existedUser: false,
            erProcess: false,
        };
        try{
            // TODO: Implement a subscription solution based on API requests
            const res = await requestMinimumTimeoutWrapper(subscribeToEmailListFn, { url: url, timeout: 5000 });
            const data = res.data;
            requestStatus.existedUser =  data.split('<br>')[2].includes('is already on this list');
        }catch(error){
            if(error.code === "ECONNABORTED"){
                const noProxyURL = new URL(subscritionAPI);
                noProxyURL.search = url.search;
                // axios cannot bypass cors 
                fetch(noProxyURL, {
                    method: 'GET',
                    mode: 'no-cors',
                    headers: {
                        'Content-Type': 'text/html'
                    },
                });
            }else{
                requestStatus.erProcess = true;
            }
        }
        return requestStatus
    }

    const onSent = async (e) => {
        // Prevents the default function of submit and reloading the page
		e.preventDefault();
        if(emailState){
            // Sent request & update state 
            setSubmitState(statesManagement[1]);
            const uriEncoded = new URLSearchParams({...paramsAPI, name:nameState, email:emailState}).toString();
            url.search = uriEncoded
            const requestStatus = await request(url)
            //Update the UI
            setSubscribed(true)
            if (requestStatus.erProcess){
                setSubscriptionState({...subscriptionState, name:nameState, email:emailState, requestError:requestStatus.erProcess})
                setSubmitState(statesManagement[3])
            } else {
                if(requestStatus.existedUser){
                    // User already exists 
                    setSubscriptionState({...subscriptionState, name:nameState, email:emailState,alreadySubscribed:requestStatus.existedUser});
                } else {
                    //User is new
                    setSubscriptionState({...subscriptionState, name:nameState, email:emailState});
                }
                // Update request state
                setSubmitState(statesManagement[2])
            }
        }
        
    }

    const nameDiv= () =>{
        var nameBox = null;
        switch(submitState){
            case(statesManagement[0]):
                // Initial state
                nameBox=<TextField type="name" placeholder="Nombre" name="name" value={nameState} onChange={(e) => setNameState(e.target.value)} style={{width: "80%"}}/>
                break;
            case(statesManagement[1]):
                // Loading state
                nameBox= <CircularProgress color="primary" size={30}/>
                break;
            case(statesManagement[2]):
                // Success state
                nameBox=  <CheckCircleOutlineIcon fill={theme.palette.success.main} height={30} alt="circleButton" />;
                break;
            default:
                // Error
                nameBox= <ErrorIcon height={30} alt="errorIcon" />;
        }
        return nameBox;
    }

    const emailDiv = () => {
        var submitBox = null;
        switch(submitState){
            case(statesManagement[0]):
                // Initial state
                submitBox=<TextField type="email" placeholder="Email" name="email" value={emailState} onChange={(e) => setEmailState(e.target.value)} style={{width: "80%"}}/>
                break;
            case(statesManagement[1]):
                // Loading state
                submitBox=<Typography>{nameState} estamos en ello ...</Typography>
                break;
            case(statesManagement[2]):
                // Success
                submitBox=<Typography>{subscriptionState.alreadySubscribed?"¡"+nameState + " ya estás subscrito!": "Email de confirmación en camino"}</Typography>
                break;
            default:
                // Error 
                submitBox=<Typography>Ups, {nameState} inténtalo más tarde. </Typography>
        }
        return submitBox;
    }

    return (
    <form onSubmit={onSent} >
            <Grid 
                container
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent='space-evenly'
                style={{height:150}}>
                <Grid item xs={12}>
                    {nameDiv()}
                </Grid>
                <Grid item xs={12}>
                    {emailDiv()}
                    {/* <CustomSubmitFormLeft params={{emailState, setEmailState, submitState, statesManagement}}/> */}
                </Grid>
                <Grid item xs={12}>
                   <Button type="submit" variant="outlined" disabled={ submitState !== statesManagement[0] }  color="primary">Subscríbete</Button>
                </Grid>
            </Grid>
    </form>

    )
}

export default Subscribtion
