import { Box, Grid, Paper, Typography } from '@material-ui/core'

// import SubscribersWidget from '../SubscribersWidget/SubscribersWidget'
import Subscribtion from '../Subscribtion/Subscribtion'

import text from '../../txt/text.json'
/*JSON.stringify(text.landingTitle)*/


const WelcomeBox = ({setSubscribed, screenSize}) => {
    const zIndexTop = 1101; // 999 is the default z value; 1100 is the header bar z value
    return (
        <Grid container direction= 'column' alignItems= {screenSize.isMobile ? 'center' : null} 
            justifyContent= 'center' align='center' spacing= {3} style={{height:'100%', width:"100%"}}>
            <Grid item style={{width:350, zIndex:zIndexTop}}>
                <Paper elevation= {10} style={{borderRadius:"10px"}}>
                    <Grid container
                        direction="column"
                        spacing={3}
                    >
                        <Grid item align="left" style={{marginLeft:"10px"}}>
                            <Typography variant="h4"  style={{ fontWeight:"bold", whiteSpace:'pre-line'}}>
                                {text.welcome.landingTitle[0]}
                            </Typography>
                            <Typography variant="h5" style={{whiteSpace:'pre-line'}}>
                                {text.welcome.landingTitle[1]}
                            </Typography>
                        </Grid>
                        <Grid item align="left" style={{marginLeft:"10px"}}>
                            <Typography variant="h6">
                                {text.welcome.landingSubtitle /* Conoce su verdadero potencial */}
                            </Typography>
                        </Grid>
                        <Grid item style={{textAlign:'justify', textJustify: 'inter-word', marginLeft:"10px", marginRight:"10px"}}>
                            <Typography style={{ whiteSpace:'pre-line'}}>
                                {text.welcome.landingBody /* y crezcas como profesional */}
                            </Typography>
                            
                        </Grid>
                        {/* <Grid item>
                            <SubscribersWidget />
                        </Grid> */}
                        <Grid item style={{marginLeft:"10px", marginRight:"10px"}}>
                            <Typography >
                                <Box sx={{fontWeight:"bold"}}>
                                    {text.welcome.subscribeTitle}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Subscribtion setSubscribed={setSubscribed} screenSize={screenSize} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default WelcomeBox
