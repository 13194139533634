/**
 * This Module contains a set of functions to facilitate the REST API queries related to the mailing list Process
 */
import {publicThirdPartyRequest} from "./requestMethods";
import emailjs from '@emailjs/browser';

const subscribeToEmailListFn = async (params) =>{
    const {
        url,
        timeout,
    } = params;
    const res = await publicThirdPartyRequest.get(url, { timeout });
    return res
};

const sendEmailFromForm = async (params) => {
    const res = await emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE, params, process.env.REACT_APP_EMAILJS_KEY);
    return res;
};

export{
    subscribeToEmailListFn,
    sendEmailFromForm
}