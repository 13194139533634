import { useState } from 'react';

import { Box, Paper, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { amber, green, blue, pink  } from '@mui/material/colors';

import './subscriptionSuccess.css'

import Footer from '../../components/Footer/Footer';

const SubscriptionSuccess = (props) => {
    const azul ={'200': '#00ADC7' , '50': '#52ADC7'};
    const rosa ={'200': '#EE509C' , '50': '#EE719C'};
    const verde ={'200': '#00A551' , '50': '#52A551'};
    const amarillo ={'200': '#FAA919' , '50': '#FAA966'};
    const footerHeight = [40, 70] // [desktop, mobile] They represent pixels
    const screenSize = props.screenSize;
    const widgetHandler = false;
    const colorsTest = [amber, green, blue, pink];
    const colorsOurs = [amarillo, verde, azul, rosa];
    const colors = colorsOurs;
    const cLight = 200;
    const cLow = 50;
    const colorIntensity = false; // Change banground color animation
    const [colorLight, setColorLight] = useState(cLight);
    const [colorLow, setColorLow] = useState(cLow);
    const [countEven, setCountEven] = useState(0);
    const [countOdds, setCountOdds] = useState(0);
    const [countOddsNext, setCountOddsNext] = useState(1);
    const [countEvenNext, setCountEvenNext] = useState(1);
    const [gradientDegree, setGradientDegree] = useState(45);
    const [changeBackground, setChangeBackground]= useState(false);

    const styles = {
        'gradient-animation-even':{
            backgroundImage: "linear-gradient(" + gradientDegree + "deg,"+ colors[countEven][colorIntensity?colorLow : colorLight] +" 0%, "+ colors[countEvenNext][colorLight] +" 95%)",
            //transition: "background-image 2s ease",
            animation: "Gradient 15s ease infinite",
            backgroundSize: "800% 800%",
            transition: "opacity 1s ease-in-out",
            opacity: changeBackground ? 0 : 1,
        },

        'gradient-animation-odds':{
            backgroundImage: "linear-gradient("+ gradientDegree + "deg,"+ colors[countOdds][colorIntensity?colorLow : colorLight] +" 0%, "+ colors[countOddsNext][colorLight] +" 95%)",
            //transition: "background-image 2s ease",
            animation: "Gradient 15s ease infinite",
            backgroundSize: "800% 800%",
        }

    }

    const getRandomAngle = () =>{
        // It is not used
        return (Math.random() * 1000 % 360 + 1)

    }

    const bgSameColorChange = () =>{
        //set Colors to a different intensity
        if (colorLow === colorLight) {setColorLow(cLow)}
        if (changeBackground){
            let newEven = (countEven + 2) % colors.length
            setCountEven(newEven);
            setCountEvenNext(newEven)
        } else {
            let newOdd = (countOddsNext + 2) % colors.length
            setCountOdds(newOdd);
            setCountOddsNext(newOdd);
        };
        setChangeBackground(!changeBackground);
        //setGradientDegree(getRandomAngle())
    }

    const bgDifferentColorChange = () =>{
        //set Colors to High
        if (colorLow !== colorLight) {setColorLow(colorLight)}
        if (changeBackground){
            setCountEvenNext((countOdds + 1) % colors.length);
            setCountEven((countOdds) % colors.length);
        } else {
            setCountOddsNext((countEvenNext) % colors.length);
            setCountOdds((countEvenNext + 1) % colors.length);
        };
        setChangeBackground(!changeBackground);
        //setGradientDegree(getRandomAngle())
    }


    return (
        <div>
            <Box className="landing-wrapping" bgcolor={colors[countOdds][colorLow]} style={styles['gradient-animation-odds']}>
            </Box>
            <Box className="landing-wrapping" bgcolor={colors[countEven][colorLow]} style={styles['gradient-animation-even']}>
            </Box>
            <Box className="landing-root" onClick={colorIntensity? bgSameColorChange : bgDifferentColorChange}> 
                <Grid container direction="column" justifyContent="center" alignItems="center" align="center"
                    style={{height:"100%", marginBottom:screenSize.isMobile ? -footerHeight[1]+"px": -footerHeight[0]+"px"}}>   
                    <Grid item style={{width:350}}>
                        <Paper >
                        <Grid container
                        direction="column"
                        align="left"
                        spacing={2} style={{ width:"100%", paddingLeft:10, paddingRight:10}}>
                                <Grid item align="center">
                                    <Typography variant="h4">
                                        <Box fontWeight="bold">
                                            ¡Todo listo!
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        Te informaremos cada vez que saquemos nuevo contenido.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        Mientras tanto, puedes disfrutar de nuestro contenido en las redes.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Footer screenSize={screenSize} heightRelative={footerHeight} />
            </Box>
        </div>
    )
}

export default SubscriptionSuccess
