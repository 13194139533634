import { useState } from 'react';
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { sendEmailFromForm } from '../../aux/emailListQueries';
import { Grid, TextField } from '@material-ui/core';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
// Style
import './findSpecialistForm.css'

const FindSpecialistForm = ({ setFormOutcome }) => {
    const [findSpecialistForm, setFindSpecialistForm] = useState({
        firstName: '',
        lastName: '',
        country: '',
        city: '',
        email: '',
        situation: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const resetForm = () => {
        setFindSpecialistForm({
            firstName: '',
            lastName: '',
            country: '',
            city: '',
            email: '',
            situation: '',
        });
    }

    const onSubmit = async (e) => {
        try{
            e.preventDefault();
            setIsLoading(true);
            await requestMinimumTimeoutWrapper( sendEmailFromForm, e.target)
            setIsLoading(false);
            setFormOutcome({
                state: 'succeeded',
                values: {...findSpecialistForm}
            });
        }catch(error){
            setFormOutcome({
                state: 'error',
                message: JSON.stringify(error),
                values: {...findSpecialistForm},
            });
        }finally{
            resetForm();
        }
    }

    const handleOnChange = (e) => {
        const tempForm = {...findSpecialistForm};
        const field = e.target.name;
        tempForm[field] = e.target.value;
        setFindSpecialistForm(tempForm)
    }

    return (
        <form onSubmit={onSubmit}>
            < Grid container justifyContent='space-between' style={{width:"100%"}}>
                <Grid item className='find-specialist-form-item' xs={5}>
                    <TextField type="text" required name="firstName" label="Nombre" disabled={isLoading}
                    value={findSpecialistForm.firstName} onChange={handleOnChange} fullWidth />
                </Grid>
                <Grid item className='find-specialist-form-item' xs={6}>
                    <TextField type="text" required name="lastName" label="Apellidos" disabled={isLoading}
                    value={findSpecialistForm.lastName} onChange={handleOnChange} fullWidth />
                </Grid>
                <Grid item className='find-specialist-form-item' xs={5}>
                    <TextField type="text" required name="country" label="País" disabled={isLoading}
                    value={findSpecialistForm.country} onChange={handleOnChange} fullWidth />
                </Grid>
                <Grid item className='find-specialist-form-item' xs={6}>
                    <TextField type="text" required name="city" label="Ciudad" disabled={isLoading}
                    value={findSpecialistForm.city} onChange={handleOnChange} fullWidth />
                </Grid>
                <Grid item className='find-specialist-form-item' xs={12}>
                    <TextField type="email" required name="email" label="Email" disabled={isLoading}
                    value={findSpecialistForm.email} onChange={handleOnChange} fullWidth />
                </Grid>
                <Grid item className='find-specialist-form-item-larger' xs={12}>
                    <TextField type="text" multiline minRows={4} maxRows={10} required name="situation" label="Motivo de la consulta"
                    disabled={isLoading} value={findSpecialistForm.situation} onChange={handleOnChange} variant="outlined" fullWidth />
                </Grid>
                <Grid item className='find-specialist-form-button' xs={12} align="center">
                    <ButtonLoading isLoading={isLoading} type='submit' label="Enviar solicitud" color="primary" variant="outlined" />
                </Grid>
            </Grid>
        </form>
    )
}

export default FindSpecialistForm